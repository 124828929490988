import { Link } from 'react-router-dom';

export interface PostImageProps {
  image?: any;
  linksTo: string;
}

const PostImage: React.FC<PostImageProps> = (props) => {
  const { image, linksTo } = props;

  return (
    <div className="margin-top-bascule">
      <div className="col-md-12">
        <div className="ttm-featured-wrapper ttm-portfolio-featured-wrapper">
          <Link to={linksTo}>
            <img className="img-fluid" src={image} alt="" style={{ maxHeight: 350 }} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostImage;

// Specifies the default values for props:
PostImage.defaultProps = {
  image: require('../../../../static/images/bascule/post1-listed.jpg').default,
  linksTo: '#',
};
