import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { TiArrowLeft, TiArrowRight } from 'react-icons/ti';

export interface PaginationProps {
  /** The initial page */
  initial?: number;
  /** The total pages */
  totalPages: number;
  /** The max pages displayed left/right (included the current one) */
  maxVisible?: number;
  /** If true, display arrows to navigate to the next and previous page */
  arrows?: boolean;
  /** Function that executes when the page is changed */
  onPageChange?: (pageIndex: number) => void;
}

const defaultProps = {
  initial: 1,
  arrows: true,
  onPageChange: () => {},
};

const range = (from: number, to: number, step = 1) => {
  let i = from;
  const range: number[] = [];
  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    initial = defaultProps.initial,
    totalPages,
    maxVisible,
    arrows = defaultProps.arrows,
    onPageChange = defaultProps.onPageChange,
  } = props;

  const [current, setCurrent] = useState(initial);

  const _generateArrayOfNumbers = () => {
    if (!maxVisible) return range(2, totalPages - 1);

    return range(
      current > maxVisible ? current - maxVisible + 1 : 2,
      current < totalPages - maxVisible ? current + maxVisible - 1 : totalPages - 1,
    );
  };

  const pagesArray = useMemo(_generateArrayOfNumbers, [current, totalPages, maxVisible]);

  const _handlePageChange = (pageIndex: number) => () => {
    setCurrent(pageIndex);
    onPageChange(pageIndex);
  };

  return (
    <div className="ttm-pagination" role="navigation" aria-label="Pagination Navigation">
      {arrows && current !== 1 && (
        <button
          data-testid="left-arrow"
          className="previous page-numbers"
          onClick={_handlePageChange(current - 1)}
        >
          <TiArrowLeft fontSize={22} />
        </button>
      )}
      <button
        data-testid="first-page"
        aria-label="Go to page 1"
        onClick={_handlePageChange(1)}
        className={classNames('page-numbers', {
          current: current === 1,
        })}
      >
        1
      </button>
      {maxVisible && current > maxVisible + 1 && (
        <button
          data-testid="previous-group"
          aria-label="previous-group"
          onClick={_handlePageChange(current - maxVisible)}
          className="page-numbers"
        >
          ...
        </button>
      )}
      {pagesArray.map((pageIndex: number) => (
        <button
          key={pageIndex}
          data-testid={`page-${pageIndex}`}
          aria-label={`Go to page ${pageIndex}`}
          onClick={_handlePageChange(pageIndex)}
          className={classNames('page-numbers', {
            current: current === pageIndex,
          })}
        >
          {pageIndex}
        </button>
      ))}
      {maxVisible && current < totalPages - maxVisible && (
        <button
          data-testid="next-group"
          aria-label="next-group"
          onClick={_handlePageChange(current + maxVisible)}
          className="page-numbers"
        >
          ...
        </button>
      )}
      {totalPages > 1 && (
        <button
          data-testid="last-page"
          aria-label={`Go to page ${totalPages}`}
          onClick={_handlePageChange(totalPages)}
          className={classNames('page-numbers', {
            current: current === totalPages,
          })}
        >
          {totalPages}
        </button>
      )}
      {arrows && current !== totalPages && (
        <button
          data-testid="right-arrow"
          className="next page-numbers"
          onClick={_handlePageChange(current + 1)}
        >
          <TiArrowRight fontSize={22} />
        </button>
      )}
    </div>
  );
};

Pagination.defaultProps = defaultProps;

export default Pagination;
