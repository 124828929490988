import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Dropdown from './Dropdown/Dropdown';
import { TiArrowDownOutline } from 'react-icons/ti';

export interface NavItemProps {
  label: string;
  type: 'LINK' | 'BUTTON' | 'DROPDOWN_LINK' | 'DROPDOWN_BUTTON';
  path: string;
  icon?: React.ReactElement;
  childrenItems?: any[];
}

const NavItem: React.FC<NavItemProps> = (props) => {
  const { label, type, path, icon, childrenItems } = props;

  const { pathname } = useLocation();

  const fixedPath = path?.startsWith('/') ? path : `/${path}`;

  return (
    <>
      {type === 'LINK' && (
        <li
          className={classNames({
            active: pathname === fixedPath,
          })}
        >
          {icon}
          <Link to={fixedPath}>{label}</Link>
        </li>
      )}
      {type === 'BUTTON' && (
        <Link
          className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-white"
          style={{ marginRight: 8 }}
          to={fixedPath}
        >
          {label}
        </Link>
      )}
      {type === 'DROPDOWN_BUTTON' && (
        <Dropdown type="BUTTON" mainItemLabel={label} childrenItems={childrenItems} />
      )}
      {type === 'DROPDOWN_LINK' && (
        <Dropdown type="LINK" mainItemLabel={label} childrenItems={childrenItems} />
      )}
    </>
  );
};

export default NavItem;
