import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import useOnClickOutside from './hooks/use-click-outside/useClickOutside';
import { Link, useLocation } from 'react-router-dom';
import { TiArrowDownOutline } from 'react-icons/ti';
import { FaCaretDown } from 'react-icons/fa';

export interface DropdownProps {
  type: 'LINK' | 'BUTTON';
  mainItemLabel: string;
  childrenItems: any[];
}

const Dropdown: React.FC<any> = (props) => {
  const { type, mainItemLabel, childrenItems } = props;

  const ref: React.RefObject<any> = useRef();

  const { pathname } = useLocation();

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  useOnClickOutside(ref, () => setIsDropdownOpened(false));

  useEffect(() => {
    setIsDropdownOpened(false);
  }, [pathname]);

  const mainItemProps = {
    'data-toggle': 'dropdown',
    'aria-haspopup': true,
    'aria-expanded': isDropdownOpened,
  };

  const dropdownMenu = (
    <div
      className={classNames('dropdown-menu custom-dropdown-button', {
        show: isDropdownOpened,
        'dropdown-position': type === 'LINK',
      })}
    >
      {childrenItems
        .sort((a, b) => a.display_order - b.display_order)
        .map((childItem) => (
          <Link
            key={childItem.label + '-' + childItem.links_to}
            className="dropdown-item"
            to={childItem.links_to}
          >
            {childItem.label}
          </Link>
        ))}
    </div>
  );

  return (
    <>
      {type === 'BUTTON' && (
        <div className="btn-group custom-dropdown-button" ref={ref}>
          <button
            {...mainItemProps}
            type="button"
            className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-white"
            onClick={() => setIsDropdownOpened(!isDropdownOpened)}
            onMouseOver={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
          >
            {mainItemLabel}
            <span className="mx-1">
              <FaCaretDown
                style={{ marginTop: -4, transition: 'color 0.5s' }}
                color={isButtonHovered ? 'black' : 'white'}
                size="1.2rem"
              />
            </span>
          </button>
          {dropdownMenu}
        </div>
      )}
      {type === 'LINK' && (
        <li
          className={classNames('btn-group custom-link-dropdown', {
            active: childrenItems.some((childItem) => pathname.includes(childItem.links_to)),
          })}
          ref={ref}
        >
          <a
            {...mainItemProps}
            role="button"
            onClick={() => setIsDropdownOpened(!isDropdownOpened)}
            onMouseOver={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
          >
            {mainItemLabel}
            <span className="bg-transparent mx-1">
              <FaCaretDown
                style={{ marginTop: -4, transition: 'color 0.5s' }}
                color={
                  isButtonHovered
                    ? '#25c9d1'
                    : matchMedia('only screen and (min-width: 1200px)').matches
                    ? 'white'
                    : 'black'
                }
                size="1.2rem"
              />
            </span>
          </a>
          {dropdownMenu}
        </li>
      )}
    </>
  );
};

export default Dropdown;
