import BottomHeader from './bottom-header/BottomHeader';
import TopHeader from './top-header/TopHeader';

import useSiteDataQuery from '../../api/hooks/use-site-data-query/useSiteDataQuery';

const Header: React.FC = () => {
  const { data: { menuItems = [], firstPageInfo = [] } = {} } = useSiteDataQuery({
    select: (data) => ({
      firstPageInfo: data.firstPageOptions,
      menuItems: data.menuItems,
    }),
  });

  return (
    <header id="masthead" className="header ttm-header-style-overlay">
      <div className="ttm-header-wrap">
        <div
          id="ttm-stickable-header-w"
          className="ttm-stickable-header-w ttm-bgcolor-darkgrey clearfix"
        >
          <TopHeader firstPageInfo={firstPageInfo} />
          <BottomHeader menuItems={menuItems} />
        </div>
      </div>
    </header>
  );
};

export default Header;
