import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import Label from '../../../shared/components/label/Label';
import { MembersDispatchContext, MembersStateContext } from '../context/MembersContext';
import { postLogin } from '../../../api/client/api';

const LoginPagePayment = (props) => {
  const { mutateAsync: sendLoginForm, isLoading: isFormLoading } = useMutation((formData: any) =>
    postLogin(formData),
  );

  const { register, handleSubmit, setValue } = useForm();

  const membersState = useContext(MembersStateContext);
  const setMembersState = useContext(MembersDispatchContext);

  useEffect(() => {
    setValue('email', membersState.data.email);
  }, [setValue, membersState.data.email]);

  const onSubmit = async (data) => {
    try {
      const response = await sendLoginForm({
        email: data.email?.trim(),
        password: data.password?.trim(),
      });
      if (response.hasOwnProperty('success') && !response.success) {
        toast.error(
          response.message || 'The credentials you provided are not correct. Please try again.',
          { closeButton: true, autoClose: false, position: 'bottom-center' },
        );
      } else {
        sessionStorage.setItem('payment_user', JSON.stringify(response));
        setMembersState({
          data: { reset: false },
          type: 'invoiceData',
        });
      }
    } catch (error) {
      toast.error('An error has occurred. Please try again later or contact us.', {
        toastId: 'login-page-error',
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
      });
    }
  };

  /*
  const _handleForgotPassword = () => {
    setMembersState({
      type: 'forgotPassword',
    });
  };
  */

  return (
    <>
      {/* <Header subtitle="Please enter your password" /> */}
      <Label>If you are already a member, please log in by entering your password</Label>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginTop: '2.5rem', marginBottom: '2.5rem' }}
      >
        <fieldset disabled={isFormLoading}>
          <div className="form-group">
            <label htmlFor="email" className="required">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              ref={register({ required: true })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="required">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="form-control"
              ref={register({ required: true })}
              required
            />
          </div>
        </fieldset>
        <div>
          <button
            type="submit"
            disabled={isFormLoading}
            className="ttm-btn
            ttm-btn-size-xs
            ttm-btn-shape-square
            ttm-btn-style-fill
            ttm-btn-bgcolor-skincolor
            ttm-btn-color-white"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default LoginPagePayment;
