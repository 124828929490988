import classNames from 'classnames';

import NavBar from './nav-bar/NavBar';
import Logo from '../logo/Logo';
import useIsScrolled from '../hooks/use-is-scrolled/useIsScrolled';
import { Link } from 'react-router-dom';

export interface BottomHeaderProps {
  menuItems: any[];
}

const BottomHeader: React.FC<BottomHeaderProps> = (props) => {
  const { menuItems } = props;

  const isScrolled: boolean = useIsScrolled();

  return (
    <div id="site-header-menu" className="site-header-menu">
      <div
        className={classNames('site-header-menu-inner', 'ttm-stickable-header', {
          'visible-title fixed-header': isScrolled,
        })}
      >
        <div className="container">
          <div className="site-branding">
            <Link className="home-link" to="/" title="Bascule" rel="home">
              <Logo />
            </Link>
          </div>
          <NavBar menuItems={menuItems} />
        </div>
      </div>
    </div>
  );
};

export default BottomHeader;
