import { Link } from 'react-router-dom';

export interface CommonButtonProps {
  className?: string;
  linksTo: string;
  buttonText?: string;
  children?: any;
}

const CommonButton: React.FC<CommonButtonProps> = (props) => {
  const { linksTo, buttonText, children, className } = props;

  return (
    <Link className={className} to={linksTo}>
      {buttonText || children}
    </Link>
  );
};

CommonButton.defaultProps = {
  className:
    'ttm-btn ttm-btn-size-xs ttm-btn-shape-square ttm-btn-style-fill ttm-btn-bgcolor-skincolor ttm-btn-color-white',
};

export default CommonButton;
