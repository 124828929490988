export interface FooterItemContainerProps {
  label: string;
  className: string;
  children?: React.ReactElement;
}

const FooterItemContainer: React.FC<FooterItemContainerProps> = (props) => {
  const { label, className, children } = props;

  return (
    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
      <div className={className}>
        <h3 className="widget-title">{label}</h3>
        {children}
      </div>
    </div>
  );
};

FooterItemContainer.defaultProps = {
  className: 'widget widget_text clearfix',
};

export default FooterItemContainer;
