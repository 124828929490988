import { Link } from 'react-router-dom';
import { IconType } from 'react-icons';

export interface BreadcrumbItemProps {
  /** The title of the path */
  title: string;
  /** renders the item as link */
  linksTo?: string;
  /** renders a react-icon component */
  Icon?: IconType;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = (props) => {
  const { title, linksTo, Icon } = props;

  return (
    <span>
      {Icon && (
        <Icon
          style={{ marginRight: 6, display: 'inline-block', marginBottom: 2 }}
          data-testid={`${title}-icon`}
        />
      )}
      {linksTo && (
        <Link title={title} to={linksTo}>
          {title}
        </Link>
      )}
      {!linksTo && title}
    </span>
  );
};

export default BreadcrumbItem;
