import useSiteDataQuery from '../../api/hooks/use-site-data-query/useSiteDataQuery';
import ErrorTemplate from '../../templates/error/ErrorTemplate';

import HomeTemplate from '../../templates/home';

const HomeScreen = () => {
  const { data: postCards = [], isError } = useSiteDataQuery({
    select: (data: any) => data.postCards,
  });

  if (isError) {
    return <ErrorTemplate />;
  }

  return <HomeTemplate postCards={postCards} />;
};

export default HomeScreen;
