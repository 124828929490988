import { Link } from 'react-router-dom';

export interface CarouselItemProps {
  post: any;
}

const CarouselItem: React.FC<CarouselItemProps> = (props) => {
  const { post } = props;

  return (
    <div className="featured-imagebox ttm-bgcolor-white box-shadow mb-20">
      <div className="featured-thumbnail">
        <img className="img-fluid" src={post.image} alt="bascule-feature" />
      </div>
      <div className="ttm-box-bottom-content">
        {/* <div className="featured-icon">
          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
            {post.icon}
          </div>
        </div> */}
        <div className="featured-title featured-title">
          <h5>
            <Link to={`/blog/post/${post.id}`}>{post.title}</Link>
          </h5>
        </div>
        <div className="featured-desc">
          <p dangerouslySetInnerHTML={{ __html: post.abstract }}></p>
          <Link
            to={`/blog/post/${post.id}`}
            className="ttm-btn ttm-btn-size-sm ttm-btn-color-darkgrey btn-inline"
          >
            READ MORE
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
