import backendClient from './backend.client';
import membersClient from './members.client';

export const fetchPostById = async (id: string) => {
  const response = await backendClient.get(`posts/id/${id}`);

  return response.data;
};

export const fetchPostBySlug = async (slug: string, token: string = '') => {
  const response = await backendClient.get(`posts/${slug}?token=${token}`);

  return response.data;
};

export const fetchPostsPaginated = async (
  page: number,
  type: string = 'Public Blog',
  token = '',
) => {
  const response = await backendClient.get(`/posts?page=${page}&type=${type}&token=${token}`);

  return response.data;
};

export const fetchSiteData = async () => {
  const response = await backendClient.get(`/data`);

  return response.data;
};

export const postLogin = async (data) => {
  const response = await membersClient.post('/login', data);

  return response.data;
};

export const postLogout = async (data) => {
  const response = await membersClient.post('/logout', data);

  return response.data;
};

export const postContactForm = async (submitData) => {
  const response = await backendClient.post('/contactUsEmail', submitData);

  return response.data;
};

export const postClaimsForm = async (submitData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const formData = new FormData();
  Object.keys(submitData).forEach((key) => {
    if (key !== 'files') formData.append(key, submitData[key]);
  });

  submitData.files?.forEach((file) => {
    formData.append('files[]', file);
  });

  const response = await backendClient.post('/claimsEmail', formData, config);

  return response.data;
};
