import SimplePageTemplate from '../../templates/simple-page/SimplePageTemplate';

const PrivacyPolicy = () => {
  return (
    <>
      <SimplePageTemplate
        title="Privacy Policy"
        content='          
        <p>We take your privacy and confidentiality very seriously and are committed to safeguarding and preserving your personal information. Bascule Insurance Services LLC will take all reasonable steps to ensure that any information you provide via this website is kept secure.</p>
    <p>This Privacy Policy explains what happens to any personal data that you provide to us, or that we collect from you whilst you visit our site.</p>
    <p>We do update this Policy from time to time so please do review this Policy regularly.</p>
    <p><strong>Information We Collect</strong></p>
    <p>In running and maintaining our website we may collect and process the following data about you:</p>
    <p>i. Information about your use of our site including details of your visits such as pages viewed and the resources that you access. Such information includes traffic data, location data and other communication data.</p>
    <p>ii. Information provided when you register for a quote or purchase an insurance policy.</p>
    <p>iii. Information that you provide when you communicate with us by any means.</p>
    <p>Please note that we are required to collect certain information from you such as your name, address, contact information and date of birth in order to underwrite any quotations or policies you receive from us.</p>
    <p><strong>Use of Your Information</strong></p>
    <p>We use the information that we collect from you to provide our services to you.&nbsp; In addition to this we may use the information for one or more of the following purposes:</p>
    <p>i. To provide information to you that you request from us relating to our products or services.</p>
    <p>ii. To inform you of any changes to our website, services or goods and products.</p>
    <p><strong>Storing Your Personal Data</strong></p>
    <p>In operating our website it may become necessary to transfer data that we collect from you to locations outside of the European Union for processing and storing. By providing your personal data to us, you agree to this transfer, storing or processing. We do our upmost to ensure that all reasonable steps are taken to make sure that your data is treated stored securely. We cannot guarantee the security of data that you choose to send us electronically, sending such information is entirely at your own risk.</p>
    <p><strong>Disclosing Your Information</strong></p>
    <p>We will not disclose your personal information to any other party other than in accordance with this Privacy Policy and in the circumstances detailed below:</p>
    <p>i. To other insurance companies in order to administer the policy for underwriting and claims administration.</p>
    <p>ii. Where we are legally required by law to disclose your personal information.</p>
    <p>iii. To further fraud protection and reduce the risk of fraud.</p>
    <p><strong>Third Party Links</strong></p>
    <p>On occasion we include links to third parties on this website. Where we provide a link it does not mean that we endorse or approve that site’s policy towards visitor privacy. You should review their privacy policy before sending them any personal data.</p>
    <p><strong>Access to Information</strong></p>
    <p>In accordance with the Data Protection Act 1998 you have the right to access any information that we hold relating to you. Please note that we reserve the right to charge a fee of £10 to cover costs incurred by us in providing you with the information.</p>
    <p><strong>Use of Cookies</strong></p>
    <p>Cookies provide information regarding the computer used by a visitor to our site. We may use cookies where appropriate to gather information about your computer in order to assist us in improving our website.</p>
    <p>We may gather information about your general internet use by using the cookie.&nbsp; Where used, these cookies are downloaded to your computer and stored on the computer’s hard drive. Such information will not identify you personally. It is statistical data. This statistical data does not identify any personal details whatsoever.</p>
    <p>You can adjust the settings on your computer to decline any cookies if you wish.&nbsp; This can easily be done by activating the reject cookies setting on your computer/internet browser.</p>
    <p><strong>Contacting Us</strong></p>
    <p>Please do not hesitate to contact us regarding any matter relating to this Privacy Policy at <a href="mailto:william.hill@basculeuw.com">william.hill@basculeuw.com</a></p>'
      />
    </>
  );
};

export default PrivacyPolicy;
