import { UseQueryOptions, useQuery } from 'react-query';

import { fetchPostBySlug } from '../../client/api';

const usePostQuery = (slug: string, token: string = '', options: UseQueryOptions = {}) => {
  return useQuery<any, any>(['post', slug], () => fetchPostBySlug(slug, token), {
    staleTime: 5 * 1000 * 60,
    ...options,
  });
};

export default usePostQuery;
