import { TiHomeOutline } from 'react-icons/ti';

import PostPreview from '../../shared/components/post/post-preview/PostPreview';
import Pagination from '../../shared/components/pagination/Pagination';
import PageTitle from '../../shared/components/page-title/PageTitle';

export interface MembersAreaTemplateProps {
  title: string;
  subtitle: string;
  posts: any[];
  totalPages: number;
  onPageChange: (pageIndex: number) => void;
}

const MembersAreaTemplate = (props) => {
  const { title, subtitle, posts, totalPages, onPageChange } = props;

  return (
    <>
      <PageTitle
        title={title}
        subTitle={subtitle}
        path={{
          title: 'Home',
          Icon: TiHomeOutline,
          linksTo: '/',
          child: {
            title: title,
          },
        }}
      />
      <div className="site-main">
        <section className="ttm-row single-project-section clearfix">
          <div className="container">
            {posts.map((post) => {
              const imagePath = post.files?.find((file) => file.featured)?.path;

              return (
                <PostPreview
                  key={post.id}
                  post={{
                    title: post.title,
                    id: post.slug,
                    date: new Date(post.created_at),
                    content: post.abstract?.replaceAll(
                      'storage/uploads/',
                      `${process.env.REACT_APP_STATIC_URL}/uploads/`,
                    ),
                    image: imagePath ? `${process.env.REACT_APP_STATIC_URL}/${imagePath}` : '',
                    isPrivate: true,
                  }}
                />
              );
            })}
          </div>
        </section>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <Pagination totalPages={totalPages} maxVisible={3} onPageChange={onPageChange} />
        </div>
      </div>
    </>
  );
};

export default MembersAreaTemplate;
