import Breadcrumb, { BreadcrumbPath } from '../breadcrumb/Breadcrumb';

export interface PageTitleProps {
  title: string;
  subTitle?: string;
  path?: BreadcrumbPath;
  postDate?: string;
}

const PageTitle: React.FC<PageTitleProps> = (props) => {
  const { title, subTitle, path, postDate } = props;

  return (
    <div className="ttm-page-title-row-bascule">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-box">
              <div className="page-title-heading">
                <h2 className="title">{title}</h2>
                {subTitle && <h5 className="title">{subTitle}</h5>}
              </div>
              {postDate && (
                <div className="featured-content featured-content-post">
                  <div className="post-meta">
                    <span className="ttm-meta-line">{postDate}</span>
                  </div>
                  <div className="separator">
                    <div className="sep-line solid mt-10 mb-20" />
                  </div>
                </div>
              )}
              {path && <Breadcrumb path={path} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
