import { useContext, useState } from 'react';

import usePostsQuery from '../../api/hooks/use-posts-query/usePostsQuery';
import ErrorTemplate from '../../templates/error/ErrorTemplate';
import MembersAreaTemplate from '../../templates/members-area';
import { postLogout } from '../../api/client/api';
import { MembersDispatchContext } from './context/MembersContext';

export interface MembersAreaScreenProps {
  title?: string;
  subtitle?: string;
}

const defaultProps = {
  title: 'Members Area',
  subtitle: 'The latest news and links to information for our members',
};

const MembersAreaScreen: React.FC<MembersAreaScreenProps> = (props) => {
  const { title, subtitle } = props;

  const dispatch = useContext(MembersDispatchContext);

  const paymentUserJson = sessionStorage.getItem('payment_user');
  let paymentUser;
  try {
    paymentUser = JSON.parse(paymentUserJson || '');
  } catch (err) {
    console.log(err);
  }

  const [page, setPage] = useState(1);

  const logoutHandler = () => {
    postLogout({ session_id: paymentUser.token });
    sessionStorage.removeItem('payment_user');
    dispatch({
      data: {
        email: '',
      },
      type: '',
    });
  };

  let result = usePostsQuery(page, 'Members Blog', paymentUser.token);
  if (result.data === undefined && result.isLoading !== true) {
    logoutHandler();

    return <></>;
  }

  const { data: posts, isSuccess, isError } = result;

  const _onPageChange = (pageIndex: number) => {
    setPage(pageIndex);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  if (isError) {
    return <ErrorTemplate />;
  }

  return isSuccess ? (
    <>
      <MembersAreaTemplate
        title={title}
        subtitle={subtitle}
        posts={posts.data}
        totalPages={posts.last_page}
        onPageChange={_onPageChange}
      />
      <a href="#" onClick={logoutHandler}>
        Log out
      </a>
    </>
  ) : (
    <></>
  );
};

MembersAreaScreen.defaultProps = defaultProps;

export default MembersAreaScreen;
