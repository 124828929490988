import { useState } from 'react';

import usePublicPostsQuery from '../../api/hooks/use-public-posts-query/usePublicPostsQuery';
import ErrorTemplate from '../../templates/error/ErrorTemplate';
import BlogTemplate from '../../templates/blog';

export interface BlogScreenProps {
  title?: string;
  subtitle?: string;
}

const defaultProps = {
  title: 'Blog',
  subtitle: 'The latest blog and links to information and stories',
};

const BlogScreen: React.FC<BlogScreenProps> = (props) => {
  const { title, subtitle } = props;

  const [page, setPage] = useState(1);

  const { data: publicPosts, isSuccess, isError } = usePublicPostsQuery(page, 'Public Blog');

  const _onPageChange = (pageIndex: number) => {
    setPage(pageIndex);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  if (isError) {
    return <ErrorTemplate />;
  }

  return isSuccess ? (
    <BlogTemplate
      title={title}
      subtitle={subtitle}
      posts={publicPosts.data}
      totalPages={publicPosts.last_page}
      onPageChange={_onPageChange}
    />
  ) : (
    <></>
  );
};

BlogScreen.defaultProps = defaultProps;

export default BlogScreen;
