import { Fragment, useMemo } from 'react';
import BreadcrumbItem, { BreadcrumbItemProps } from './breadcrumb-item/BreadcrumbItem';

export interface BreadcrumbPath extends BreadcrumbItemProps {
  child?: BreadcrumbPath;
}

export interface BreadcrumbProps {
  /** The path */
  path: BreadcrumbPath;
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const { path } = props;

  const arrayFromLinkedPath = (): BreadcrumbPath[] => {
    let arr: BreadcrumbPath[] = [];
    let current: BreadcrumbPath | undefined = path;
    while (current !== undefined) {
      arr = [...arr, { ...current }];
      current = current.child;
    }

    return arr;
  };

  const pathArray = useMemo(arrayFromLinkedPath, [path]);

  return (
    <div className="breadcrumb-wrapper">
      {pathArray.map(({ title, linksTo, Icon }, index: number) => (
        <Fragment key={title}>
          <BreadcrumbItem Icon={Icon} title={title} linksTo={linksTo} />
          {index < pathArray.length - 1 && (
            <span aria-label="separator" className="ttm-bread-sep">
              &nbsp; | &nbsp;
            </span>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
