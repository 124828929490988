import Spinner from './Spinner';

const Loader = () => {
  return (
    <>
      <div className="ttm-page-title-row-bascule">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box">
                <div className="page-title-heading"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main" style={{ height: 500 }}>
        <section className="ttm-row single-project-section clearfix">
          <div className="container">
            <Spinner />
          </div>
        </section>
      </div>
    </>
  );
};

export default Loader;
