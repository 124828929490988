import { UseQueryOptions, useQuery } from 'react-query';

import { fetchPostsPaginated } from '../../client/api';

const usePostsQuery = (
  page: number,
  postsType: string,
  token: string = '',
  options: UseQueryOptions = {},
) => {
  return useQuery<any, any>(['posts', page], () => fetchPostsPaginated(page, postsType, token), {
    keepPreviousData: true,
    staleTime: 5 * 1000 * 60,
    ...options,
  });
};

export default usePostsQuery;
