import PageTitle from '../../shared/components/page-title/PageTitle';

export interface ErrorTemplateProps {
  title: string;
  content: string;
}

const ErrorTemplate = (props) => {
  const { title, content } = props;

  return (
    <>
      <PageTitle title={title} />
      <div className="site-main">
        <section className="ttm-row single-project-section clearfix">
          <div className="container">
            <p>{content}</p>
          </div>
        </section>
      </div>
    </>
  );
};

ErrorTemplate.defaultProps = {
  title: 'An error occured',
};

export default ErrorTemplate;
