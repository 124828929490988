export interface NavInfoProps {
  label?: string;
  value: string;
  type: 'TEXT' | 'MAIL' | 'PHONE';
  icon: React.ReactElement;
}

const NavInfo: React.FC<NavInfoProps> = (props) => {
  const { label, type, icon, value } = props;

  const mailTo = `mailto: ${value}`;
  const phone = `tel: ${value}`;

  return (
    <>
      {type === 'TEXT' && (
        <li>
          {value ? icon : ''}
          <strong> {label}</strong> {value}
        </li>
      )}
      {type === 'MAIL' && (
        <li>
          {value ? icon : ''}

          <a href={mailTo}> {value}</a>
        </li>
      )}
      {type === 'PHONE' && (
        <li>
          {value ? icon : ''}
          <a href={phone}> {value}</a>
        </li>
      )}
    </>
  );
};

export default NavInfo;
