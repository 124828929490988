import React, { useState } from 'react';
//import { useLocation } from 'react-router-dom';
//import * as queryUtils from 'query-string';
//import { useMutation } from 'react-query';
//import { toast } from 'react-toastify';

import MembersLoginPage from '../login/MembersLoginPage';
import MembersAreaScreen from '../MembersAreaScreen';

export const MembersStateContext = React.createContext<any>(undefined);
export const MembersDispatchContext = React.createContext<any>(undefined);

const MembersContextProvider: React.FC<any> = (props) => {
  const { initialType = '', initialData = {} } = props;

  //const location = useLocation();

  const [MembersState, setMembersState] = useState<any>({
    data: initialData,
    type: initialType,
  });

  const _updateMembersState = (updatedState) =>
    setMembersState({
      ...MembersState,
      data: {
        ...MembersState.data,
        ...updatedState.data,
      },
      type: updatedState.type,
    });

  const _getPage = () => {
    switch (MembersState.type) {
      case 'loginPage':
        return <MembersLoginPage />;
      case 'invoiceData':
        return <MembersAreaScreen />;
      default:
        return <MembersLoginPage />;
    }
  };

  return (
    <MembersStateContext.Provider value={MembersState}>
      <MembersDispatchContext.Provider value={_updateMembersState}>
        <div className="container">{_getPage()}</div>
      </MembersDispatchContext.Provider>
    </MembersStateContext.Provider>
  );
};

export default MembersContextProvider;
