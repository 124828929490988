import '../../../static/css/owl.carousel.css';
import useScript from '../hooks/use-script/useScript';

import CarouselItem from './carousel-item/CarouselItem';

export interface CarouselProps {
  postCards: any[];
}

const Carousel: React.FC<CarouselProps> = (props) => {
  const { postCards } = props;

  const status = useScript(process.env.PUBLIC_URL + '/js/jquery.min.js');
  let OwlCarousel;
  if (status !== 'ready') {
    return null;
  } else {
    OwlCarousel = require('react-owl-carousel');
  }

  return (
    <OwlCarousel
      className="services-slide owl-carousel owl-theme mt-5"
      items={3}
      loop={true}
      nav={false}
      smartSpeed={3000}
      responsive={{
        0: {
          items: postCards.length >= 1 ? 1 : postCards.length,
        },
        576: {
          items: postCards.length >= 2 ? 2 : postCards.length,
        },
        768: {
          items: postCards.length >= 3 ? 3 : postCards.length,
        },
        1200: {
          items: postCards.length >= 3 ? 3 : postCards.length,
        },
      }}
    >
      {postCards.map((post) => {
        const imagePath = post.files?.find((file) => file.featured)?.path;

        return (
          <CarouselItem
            key={post.id}
            post={{
              id: post.slug,
              title: post.title,
              abstract: post.abstract,
              image: imagePath ? `${process.env.REACT_APP_STATIC_URL}/${imagePath}` : '',
            }}
          />
        );
      })}
    </OwlCarousel>
  );
};

export default Carousel;
