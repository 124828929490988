import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import NavItem from '../../../../shared/components/nav-item/NavItem';
import { useLocation } from 'react-router-dom';

export interface NavBarProps {
  menuItems: any[];
}

const NavBar: React.FC<NavBarProps> = (props) => {
  const { menuItems } = props;

  const { pathname } = useLocation();

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [visibleMenuItems, setVisibleMenuItems] = useState(menuItems);

  const buttonItems = useMemo(
    () => menuItems.filter((menuItem) => menuItem.framed_button && !menuItem.parent_id),
    [menuItems],
  );
  const linkItems = useMemo(
    () => menuItems.filter((menuItem) => !menuItem.framed_button && !menuItem.parent_id),
    [menuItems],
  );

  useEffect(() => {
    function handleResize() {
      matchMedia('only screen and (min-width: 1200px)').matches
        ? setVisibleMenuItems(linkItems)
        : setVisibleMenuItems(menuItems);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [menuItems]);

  useEffect(() => {
    setIsMobileMenuOpened(false);
  }, [pathname]);

  const _toggleMenu = () => {
    setIsMobileMenuOpened(!isMobileMenuOpened);
  };

  return (
    <div id="site-navigation" className="site-navigation">
      <div className="header-btn">
        {buttonItems
          .sort((a, b) => a.display_order - b.display_order)
          .map((buttonItem: any) => (
            <NavItem
              key={buttonItem.id}
              type={buttonItem.type === 'DROPDOWN' ? 'DROPDOWN_BUTTON' : 'BUTTON'}
              childrenItems={
                buttonItem.type === 'DROPDOWN'
                  ? menuItems.filter((menuItem) => menuItem.parent_id === buttonItem.id)
                  : undefined
              }
              path={buttonItem.links_to}
              label={buttonItem.label}
            />
          ))}
      </div>
      {buttonItems.length > 0 && (
        <div className="ttm-rt-contact">
          <div className="ttm-header-icons"></div>
        </div>
      )}
      <div className="ttm-menu-toggle">
        <input
          type="checkbox"
          id="menu-toggle-form"
          onChange={() => _toggleMenu()}
          className={classNames({
            active: isMobileMenuOpened,
          })}
          checked={isMobileMenuOpened}
        />
        <label htmlFor="menu-toggle-form" className="ttm-menu-toggle-block">
          <span className="toggle-block toggle-blocks-1" />
          <span className="toggle-block toggle-blocks-2" />
          <span className="toggle-block toggle-blocks-3" />
        </label>
      </div>
      <nav
        id="menu"
        className={classNames('menu', {
          active: isMobileMenuOpened,
        })}
      >
        <ul className="dropdown">
          {visibleMenuItems
            .filter((item: any) => !item.parent_id)
            .sort((a, b) => a.display_order - b.display_order)
            .map((linkItem: any) => (
              <NavItem
                key={linkItem.id}
                type={linkItem.type === 'DROPDOWN' ? 'DROPDOWN_LINK' : 'LINK'}
                childrenItems={
                  linkItem.type === 'DROPDOWN'
                    ? menuItems.filter((menuItem) => menuItem.parent_id === linkItem.id)
                    : undefined
                }
                path={linkItem.links_to}
                label={linkItem.label}
              />
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
