import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';

import { fetchPostById } from '../../api/client/api';
import ErrorTemplate from '../../templates/error/ErrorTemplate';
import SimplePageTemplate from '../../templates/simple-page/SimplePageTemplate';

const PageScreen = (props) => {
  const { postId } = props;

  const { data = {}, isSuccess, isError } = useQuery<any, any>(
    ['post', postId],
    () => fetchPostById(postId),
    {
      staleTime: 5 * 1000 * 60,
    },
  );

  const imagePath = data.files?.find((file) => file.featured)?.path;
  const imageUrl = imagePath ? `${process.env.REACT_APP_STATIC_URL}/${imagePath}` : '';
  const postUrl = `${window.location.origin}${window.location.pathname}`;

  if (isError) {
    return <ErrorTemplate />;
  }

  return isSuccess ? (
    <>
      <Helmet>
        <meta property="og:title" content={'Bascule: ' + data.title} />
        <meta property="og:url" content={postUrl} />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
      <SimplePageTemplate
        title={data.title}
        content={data.contents_html?.replaceAll(
          'storage/uploads/',
          `${process.env.REACT_APP_STATIC_URL}/uploads/`,
        )}
        image={imageUrl}
      />
    </>
  ) : (
    <></>
  );
};

export default PageScreen;
