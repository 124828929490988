import { TiHomeOutline } from 'react-icons/ti';

import PageTitle from '../../shared/components/page-title/PageTitle';

import styles from './SimplePageTemplate.module.css';

export interface SimplePageTemplateProps {
  title: string;
  content: string;
  image?: string;
}

const SimplePageTemplate: React.FC<SimplePageTemplateProps> = (props) => {
  const { title, content, image } = props;

  return (
    <>
      <PageTitle
        title={title}
        path={{
          title: 'Home',
          Icon: TiHomeOutline,
          linksTo: '/',
          child: {
            title: title,
          },
        }}
      />
      <div className="site-main">
        <section className="ttm-row single-project-section clearfix">
          <div className="container">
            <div className="row  res-991-mt-30">
              <div className={styles.pageContainer}>
                <div
                  className={styles.pageContainer__Content + ' main-content-editor'}
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
                {image && (
                  <div className={styles.pageContainer__Image}>
                    <img src={image} alt="about" className="img-fluid"></img>
                  </div>
                )}
              </div>
            </div>
            <div className="separator">
              <div className="sep-line solid mt-30 mb-30" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SimplePageTemplate;
