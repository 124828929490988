import { UseQueryOptions, useQuery } from 'react-query';

import { fetchSiteData } from '../../client/api';

const useSiteDataQuery = (options: UseQueryOptions<any> = {}) => {
  return useQuery<any>('siteData', () => fetchSiteData(), {
    staleTime: 5 * 1000 * 60,
    ...options,
  });
};

export default useSiteDataQuery;
