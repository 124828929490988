import ContactUsTemplate from '../../templates/contact-us';

export interface ContactUsScreenProps {
  title: string;
}

const defaultProps = {
  title: 'Contact Us',
};

const ContactUsScreen = (props) => {
  const { title } = props;

  return <ContactUsTemplate title={title} />;
};

ContactUsScreen.defaultProps = defaultProps;

export default ContactUsScreen;
