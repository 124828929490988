import { TiHomeOutline } from 'react-icons/ti';

import PageTitle from '../../shared/components/page-title/PageTitle';
import contactUsImg from '../../static/images/bascule/contact/contact-usa.png';
import contactGbImg from '../../static/images/bascule/contact/contact-gb.png';
import LocationItem from './location-item/LocationItem';
import ContactForm from './contact-form/ContactForm';

import styles from './ContactUsTemplate.module.css';

export interface ContactUsTemplateProps {
  title: string;
}

const ContactUsTemplate: React.FC<ContactUsTemplateProps> = (props) => {
  const { title } = props;

  return (
    <>
      <PageTitle
        title={title}
        path={{
          title: 'Home',
          Icon: TiHomeOutline,
          linksTo: '/',
          child: {
            title: title,
          },
        }}
      />
      <div className="site-main">
        <section className="ttm-row single-project-section clearfix">
          <div className="container">
            {/* <div className="widget">
              <h3 className="widget-title">Sign-Up to our newsletter</h3>
              <div>
                <span>Exclusive offers and promotions for members</span>
              </div>
              <iframe
                style={{
                  width: 350,
                  height: 350,
                }}
                scrolling="no"
                title="Sign-Up"
                src={`https://cdn.forms-content.sg-form.com/${process.env.REACT_APP_FOOTER_FORM_ID}`}
              />
            </div>
            <div className="text-center">
              <span>OR</span>
            </div> */}
            <div className={styles.contactForm}>
              <ContactForm />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUsTemplate;
