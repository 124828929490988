import NewsItem from './news-item/NewsItem';
import FooterItemContainer from '../footer-item-container/FooterItemContainer';

export interface LatestNewsItemProps {
  latestNews: any;
}

const LatestNewsItem: React.FC<any> = (props) => {
  const { latestNews } = props;

  return (
    <FooterItemContainer label="Latest Blogs" className="widget widget_text clearfix">
      <ul className="widget-post ttm-recent-post-list">
        {latestNews.map((post) => {
          const imagePath = post.files?.find((file) => file.featured)?.path;

          return (
            <li key={post.id}>
              <NewsItem
                alt={post.slug}
                slug={post.slug}
                date={new Date(post.created_at)}
                imgSrc={imagePath ? `${process.env.REACT_APP_STATIC_URL}/${imagePath}` : ''}
                title={post.title}
              />
            </li>
          );
        })}
      </ul>
    </FooterItemContainer>
  );
};

export default LatestNewsItem;
