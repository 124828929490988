import { Fragment } from 'react';
import { FaAngleDoubleRight } from 'react-icons/fa';
import NavItem from '../../../shared/components/nav-item/NavItem';
import FooterItemContainer from '../footer-item-container/FooterItemContainer';

export interface QuickLinksItemProps {
  menuItems: any[];
}

const QuickLinksItem: React.FC<QuickLinksItemProps> = (props) => {
  const { menuItems } = props;

  const items = menuItems.filter((item) => !item.parent_id);

  //
  return (
    <FooterItemContainer label="Quick Links" className="widget widget_nav_menu clearfix">
      <ul id="menu-footer-services">
        {items
          .filter((item) => item.type !== 'DROPDOWN')
          .sort((a, b) => a.display_order - b.display_order)
          .map((item) => (
            <NavItem
              key={item.id}
              type="LINK"
              path={item.links_to}
              label={item.label}
              icon={<FaAngleDoubleRight style={{ marginBottom: -2 }} />}
            />
          ))}
        {items
          .filter((item) => item.type === 'DROPDOWN')
          .sort((a, b) => a.display_order - b.display_order)
          .map((item) => (
            <Fragment key={item.id}>
              <span>{item.label}</span>
              <div className="mb-2">
                {menuItems
                  .filter((menuItem) => menuItem.parent_id === item.id)
                  .map((childItem) => (
                    <div className="ml-3" key={childItem.id}>
                      <NavItem
                        type="LINK"
                        path={childItem.links_to}
                        label={childItem.label}
                        icon={<FaAngleDoubleRight style={{ marginBottom: -2 }} />}
                      />
                    </div>
                  ))}
              </div>
            </Fragment>
          ))}
      </ul>
    </FooterItemContainer>
  );
};

export default QuickLinksItem;
