import { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import classNames from 'classnames';

import Carousel from './carousel';
import Slider from './slider';
import CommonButton from '../../shared/components/common-button/CommonButton';

export interface HomeTemplateProps {
  postCards: any[];
}

const HomeTemplate: React.FC<HomeTemplateProps> = (props) => {
  const { postCards } = props;

  const location = useLocation<any>();
  const history = useHistory();

  const _closePopup = () => {
    history.replace('/', { emailValidationError: false });
  };

  return (
    <>
      <Slider />
      <div className={classNames('site-main')}>
        <section
          className="ttm-row services2-section clearfix"
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-2 col-sm-2" />
              <div className="col-lg-6 col-md-8 col-sm-8">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>The Canter Club</h5>
                    <h2 className="title">
                      What We Offer
                      <br />
                      To Our Customers
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-2 col-sm-2" />
            </div>
            {postCards.length > 0 && (
              <div className="row">
                <Carousel postCards={postCards} />
              </div>
            )}
          </div>
        </section>
      </div>

      <Popup
        open={location?.state?.emailValidationError}
        closeOnDocumentClick
        onClose={_closePopup}
      >
        <div>
          <div>
            <p className="pb-2 mb-0">Your account is not registered for portal login yet.</p>{' '}
            <p className="pb-2 mb-0">
              Please click <Link to="payment/one-time">here</Link> to make a one-time payment or
              call us on: <a href="tel:+1 877-474-5006">+1 877-474-5006</a> for assistance
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-2">
          <button
            className="ttm-btn ttm-btn-size-xs ttm-btn-shape-square ttm-btn-style-fill ttm-btn-bgcolor-skincolor ttm-btn-color-white"
            onClick={_closePopup}
          >
            OK
          </button>
        </div>
      </Popup>
    </>
  );
};

export default HomeTemplate;
