import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';

import styles from './ProtectedSubmit.module.css';

interface ProtectedSubmitProps {
  isLoading: boolean;
  ref: any;
}

const ProtectedSubmit: React.FC<ProtectedSubmitProps> = React.forwardRef((props, ref) => {
  const { isLoading } = props;

  return (
    <div className={styles.container}>
      <button
        disabled={isLoading}
        type="submit"
        className={classNames(
          `ttm-btn
          ttm-btn-size-xs
          ttm-btn-shape-square
          ttm-btn-style-fill
          ttm-btn-bgcolor-skincolor
          ttm-btn-color-white`,
          styles.button,
        )}
      >
        Submit
      </button>
      <ReCAPTCHA
        className={styles.captcha}
        ref={ref}
        badge="inline"
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
      />
    </div>
  );
});

export default ProtectedSubmit;
