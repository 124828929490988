export interface SocialIconsRoundProps {
  linksTo: string;
  tooltip?: string;
  icon: React.ReactElement;
  target?: string;
  rel?: string;
}
const SocialIconsRound: React.FC<SocialIconsRoundProps> = (props) => {
  const { linksTo, icon, tooltip, target, rel } = props;

  return (
    <li>
      <a
        className="tooltip-top"
        target={target}
        rel={rel}
        href={linksTo}
        data-tooltip={tooltip ? tooltip : undefined}
      >
        {icon}
      </a>
    </li>
  );
};

// Specifies the default values for props:
SocialIconsRound.defaultProps = {
  target: '_blank',
  rel: 'noreferrer',
};

export default SocialIconsRound;
