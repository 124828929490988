import { format } from 'date-fns';

import NavItem from '../../../shared/components/nav-item/NavItem';

const LegalItem = () => {
  return (
    <div className="bottom-footer-text ttm-textcolor-white">
      <div className="container">
        <div className="row copyright">
          <div className="col-md-12">
            <div className="col-md-12">
              <ul id="menu-footer-menu" className="footer-nav-menu">
                {/* <NavItem label="Terms & Conditions" type="LINK" path="/" /> */}
                <NavItem label="Privacy Policy" type="LINK" path="/privacy-policy" />
                {/* <NavItem label="Cookies Policy" type="LINK" path="/" /> */}
              </ul>
            </div>
            <div>
              <span>
                Copyright © {format(new Date(), 'yyyy')}&nbsp;<a href="/">The Canter Club</a>. All
                rights reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalItem;
