import { Link } from 'react-router-dom';
import useSiteDataQuery from '../../../../api/hooks/use-site-data-query/useSiteDataQuery';

export interface SlideProps {
  image: any;
  title?: string;
  text1: string;
  text2?: string;
  buttonText?: string;
}

const defaultProps = {
  title: '',
  buttonText: 'Contact Us',
};

const Slide: React.FC<SlideProps> = (props) => {
  const {
    image,
    title = defaultProps.title,
    text1,
    text2,
    buttonText = defaultProps.buttonText,
  } = props;

  const { data: contactItem } = useSiteDataQuery({
    select: (data) => data.menuItems?.find((menuItem) => menuItem.type === 'CONTACT_US'),
  });

  return (
    <li
      data-transition="fade"
      data-slotamount="default"
      data-hideafterloop={0}
      data-hideslideonmobile="off"
      data-easein="default"
      data-easeout="default"
      data-masterspeed={300}
      data-rotate={1}
      data-saveperformance="off"
      data-title="Slide"
      data-thumb={image}
    >
      <img
        src={image}
        alt="bascule"
        title="Home 2"
        data-bgposition="center center"
        data-bgfit="cover"
        data-bgrepeat="no-repeat"
        className="rev-slidebg"
        data-no-retina
      />
      <div
        className="tp-caption tp-resizeme"
        id="slide-9-layer-2"
        data-x="['center','center','center','center']"
        data-hoffset="['0','0','-628','-684']"
        data-y="['middle','middle','middle','middle']"
        data-voffset="['-26','-86','-116','46']"
        data-fontsize="['14','14','12','11']"
        data-lineheight="['14','14','14','14']"
        data-fontweight="['600','600','700','700']"
        data-color="['rgb(255,255,255)','rgb(255,255,255)','rgb(255,255,255)','rgb(255,255,255)']"
        data-width="none"
        data-height="none"
        data-whitespace="nowrap"
        data-visibility="['on','on','off','off']"
        data-type="text"
        data-responsive_offset="on"
        data-frames='[{"delay":280,"speed":500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
        data-textalign="['inherit','inherit','inherit','inherit']"
        data-paddingtop="[0,0,0,0]"
        data-paddingright="[0,0,0,0]"
        data-paddingbottom="[0,0,0,0]"
        data-paddingleft="[0,0,0,0]"
      >
        {title}
      </div>

      <div
        className="tp-caption tp-resizeme custom-tp-text"
        id="slide-9-layer-3"
        data-x="['center','center','center','center']"
        data-hoffset="['0','0','0','0']"
        data-y="['top','top','middle','middle']"
        data-voffset="['398','278','-37','-34']"
        data-fontsize="['60','60','50','40']"
        data-lineheight="['75','75','75','60']"
        data-fontweight="['700','700','700','700']"
        data-color="['rgb(37,201,209)','rgb(37,201,209)','rgb(37,201,209)','rgb(37,201,209)']"
        data-width="none"
        data-height="none"
        data-whitespace="nowrap"
        data-type="text"
        data-responsive_offset="on"
        data-frames='[{"delay":440,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
        data-textalign="['inherit','inherit','inherit','inherit']"
        data-paddingtop="[0,0,0,0]"
        data-paddingright="[0,0,0,0]"
        data-paddingbottom="[0,0,0,0]"
        data-paddingleft="[0,0,0,0]"
      >
        {text1}
      </div>
      <div
        className="tp-caption tp-resizeme custom-tp-text"
        id="slide-9-layer-4"
        data-x="['center','center','center','center']"
        data-hoffset="['0','0','0','0']"
        data-y="['top','top','middle','middle']"
        data-voffset="['466','346','24','15']"
        data-fontsize="['60','60','50','40']"
        data-lineheight="['75','75','75','60']"
        data-fontweight="['700','700','700','700']"
        data-color="['rgb(37,201,209)','rgb(37,201,209)','rgb(37,201,209)','rgb(37,201,209)']"
        data-width="none"
        data-height="none"
        data-whitespace="nowrap"
        data-type="text"
        data-responsive_offset="on"
        data-frames='[{"delay":600,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
        data-textalign="['inherit','inherit','inherit','inherit']"
        data-paddingtop="[0,0,0,0]"
        data-paddingright="[0,0,0,0]"
        data-paddingbottom="[0,0,0,0]"
        data-paddingleft="[0,0,0,0]"
      >
        {text2}
      </div>
      {contactItem && (
        <Link
          className="tp-caption skin-flat-button tp-resizeme"
          to={contactItem.links_to}
          target="_self"
          id="slide-9-layer-5"
          data-x="['center','center','center','center']"
          data-hoffset="['0','0','0','0']"
          data-y="['middle','middle','middle','middle']"
          data-voffset="['197','137','108','84']"
          data-fontsize="['13','13','12','11']"
          data-lineheight="['13','13','12','11']"
          data-fontweight="['700','700','700','700']"
          data-width="none"
          data-height="none"
          data-whitespace="nowrap"
          data-type="text"
          data-actions
          data-responsive_offset="on"
          data-frames='[{"delay":1000,"speed":500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
          data-textalign="['inherit','inherit','inherit','inherit']"
          data-paddingtop="[16,16,14,12]"
          data-paddingright="[35,35,30,25]"
          data-paddingbottom="[16,16,14,12]"
          data-paddingleft="[35,35,30,25]"
        >
          {buttonText}
        </Link>
      )}
    </li>
  );
};

Slide.defaultProps = defaultProps;

export default Slide;
