import { useCallback, useLayoutEffect, useState } from 'react';
import { debounce } from './debounce';

interface UsIsScrolledOptions {
  onlyBigScreen?: boolean;
  scrollThresholder?: number;
}

const useIsScrolled = (options?: UsIsScrolledOptions): boolean => {
  const { onlyBigScreen = true, scrollThresholder = 50 } = options ?? {};

  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = useCallback(() => {
    const updateIsScrolled = () =>
      window.scrollY > scrollThresholder ? setIsScrolled(true) : setIsScrolled(false);

    if (onlyBigScreen) {
      matchMedia('only screen and (min-width: 1200px)').matches && updateIsScrolled();
    } else {
      updateIsScrolled();
    }
  }, [setIsScrolled, onlyBigScreen, scrollThresholder]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll, 0, { isImmediate: true }));

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [handleScroll]);

  return isScrolled;
};

export default useIsScrolled;
