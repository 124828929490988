import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { useIsFetching } from 'react-query';
import CookieConsent from 'react-cookie-consent';

import PrivacyPolicyScreen from '../screens/privacy-policy';
import HomeScreen from '../screens/home';
import BlogScreen from '../screens/blog/BlogScreen';
import PostScreen from '../screens/post/PostScreen';
//import MembersAreaScreen from '../screens/members-area/MembersAreaScreen';

import Footer from './footer';
import Header from './header';
import ScrollToTopButton from './scroll-to-top';
import ContactUsScreen from '../screens/contact-us';
import useSiteDataQuery from '../api/hooks/use-site-data-query/useSiteDataQuery';
import PageScreen from '../screens/page';
import Loader from './loader/Loader';
import ErrorTemplate from '../templates/error/ErrorTemplate';
import MembersPreLoginScreen from '../screens/members-area/members-pre-login/MembersPreLoginScreen';

const AppLayout = () => {
  const { data: menuItems = [], isError, isSuccess, isLoading } = useSiteDataQuery({
    select: (data: any) => data.menuItems,
  });

  const isFetching = useIsFetching();

  const _renderScreen = (menuItem) => {
    switch (menuItem.type.toUpperCase()) {
      case 'HOME':
        return <HomeScreen />;
      case 'CONTACT_US':
        return <ContactUsScreen />;
      case 'BLOG':
        return <BlogScreen />;
      case 'POST':
        return <PageScreen postId={menuItem.post_id} />;
      case 'MEMBERS_AREA':
        return <MembersPreLoginScreen />;
    }
  };

  return (
    <>
      <Header />
      {(isFetching > 0 || isLoading) && <Loader />}

      {isError && <ErrorTemplate />}
      {isSuccess && (
        <Switch>
          {menuItems
            .filter((menuItem) => menuItem.type !== 'DROPDOWN')
            .map((menuItem) => {
              return (
                <Route
                  key={menuItem.id}
                  path={
                    menuItem?.links_to?.startsWith('/')
                      ? menuItem.links_to
                      : `/${menuItem.links_to}`
                  }
                  exact
                >
                  {_renderScreen(menuItem)}
                </Route>
              );
            })}
          <Route path="/blog/post/:slug" exact>
            <PostScreen isPrivate={false} />
          </Route>
          <Route path="/pblog/post/:slug" exact>
            <PostScreen isPrivate={true} />
          </Route>
          <Route path="/privacy-policy" exact>
            <PrivacyPolicyScreen />
          </Route>

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      )}
      <Footer />
      <ScrollToTopButton />
      <CookieConsent
        location="bottom"
        buttonText="I agree"
        cookieName="associationCookie"
        style={{ background: '#F3F3F4', color: 'black' }}
        buttonStyle={{ background: '#25CAD2', color: 'white', fontSize: '13px' }}
        expires={150}
      >
        This site uses cookies that are essential for its function, including 3rd party cookies for
        the re-captcha mechanism. By using this site you accept the use of cookies. For more
        information please see: <Link to="/privacy-policy">Privacy Policy</Link>
      </CookieConsent>
    </>
  );
};

export default AppLayout;
