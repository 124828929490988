import { UseQueryOptions, useQuery } from 'react-query';

import { fetchPostsPaginated } from '../../client/api';

const usePublicPostsQuery = (
  page: number,
  postsType: string,
  token: string = '',
  options: UseQueryOptions = {},
) => {
  return useQuery<any, any>(
    ['publicPosts', page],
    () => fetchPostsPaginated(page, postsType, token),
    {
      keepPreviousData: true,
      staleTime: 5 * 1000 * 60,
      ...options,
    },
  );
};

export default usePublicPostsQuery;
