import { BrowserRouter as Router } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import AppLayout from './layout';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
        <Router>
          <AppLayout />
        </Router>
      </IconContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
      <ToastContainer
        autoClose={5000}
        position="bottom-right"
        closeOnClick
        pauseOnFocusLoss
        draggable
        newestOnTop={false}
        pauseOnHover
        limit={3}
      />
    </QueryClientProvider>
  );
};

export default App;
