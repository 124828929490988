import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FaAngleUp } from 'react-icons/fa';
import classNames from 'classnames';

import useIsScrolled from '../header/hooks/use-is-scrolled/useIsScrolled';

const ScrollToTopButton = () => {
  const { pathname } = useLocation();

  const isScrolled = useIsScrolled({
    onlyBigScreen: false,
    scrollThresholder: 100,
  });

  useEffect(() => {
    _scrollToTop();
  }, [pathname]);

  const _scrollToTop = (behavior: 'smooth' | 'auto' | undefined = 'smooth') => {
    window.scrollTo({ top: 0, left: 0, behavior });
  };

  return (
    <a
      id="totop"
      tabIndex={0}
      className={classNames({
        'top-visible': isScrolled,
      })}
      onClick={() => _scrollToTop()}
    >
      <FaAngleUp />
    </a>
  );
};

export default ScrollToTopButton;
