import classNames from 'classnames';
import styles from './Label.module.css';

const Label = (props) => {
  const { warning, style, children } = props;

  return <div className={classNames(style, { [styles.label]: warning })}>{children}</div>;
};

export default Label;
