import { format } from 'date-fns';
import { Link } from 'react-router-dom';

export interface NewsItemProps {
  title: string;
  slug: string;
  imgSrc: string;
  alt?: string;
  date?: Date;
}

const NewsItem: React.FC<NewsItemProps> = (props) => {
  const { title, slug, imgSrc, alt = 'post-img', date = new Date() } = props;

  return (
    <>
      {/* <Link to={`/blog/post/${slug}`}>
        <img src={imgSrc} alt={alt} />
      </Link> */}
      <span className="post-date">{format(date, 'MMMM dd, yyyy')}</span>
      <Link to={`/blog/post/${slug}`}>{title}</Link>
    </>
  );
};

export default NewsItem;
