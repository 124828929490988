import FooterItemContainer from '../footer-item-container/FooterItemContainer';
import officeImage from '../../../static/images/bascule//world-map-offices.png';
import { FaMapMarkerAlt } from 'react-icons/fa';

export interface NewsLetterItemProps {}

const NewsLetterItem: React.FC<NewsLetterItemProps> = (props) => {
  return (
    <FooterItemContainer
      label="Sign-Up to our newsletter"
      className="widget flicker_widget clearfix"
    >
      <div className="textwidget widget-text">
        <span>Exclusive offers and promotions for members</span>
        <iframe
          style={{
            width: 350,
            height: 350,
          }}
          scrolling="no"
          title="Sign-Up"
          src={`https://cdn.forms-content.sg-form.com/${process.env.REACT_APP_FOOTER_FORM_ID}`}
        />
      </div>
    </FooterItemContainer>
  );
};

export default NewsLetterItem;
